if (window.jQuery == null) {
  window.$ = require('jquery')
  window.jQuery = window.$
}

require('../../bower_components/css-element-queries/src/ResizeSensor')
window.ElementQueries = require('../../bower_components/css-element-queries/src/ElementQueries.js')
window.ElementQueries.listen()

const stickyFooter = require('./_sticky-footer')
stickyFooter.init()

const header = require('./_header')
header.init()

$(() => {
  require('./_mobilemenu')
  require('./_loginbox')
})

$(() => {
  const $currentUser = $('#current-user')
  const $currentUserImage = $currentUser.find('img')

  $('.login-avatar').attr('src', $currentUserImage.attr('src'))
  $('.login-box-name').html($currentUserImage.attr('title'))
})

const initializeSolvedButton = () => {
  // cleanup
  $('.accepted-cleanup').remove()
  $('.accepted-solution').removeClass('accepted-solution')

  // look for a "solved" button
  const $solvedButton = $('.widget-button.accepted')

  if ($solvedButton.length > 0) {
    // add text to button
    $('<span class="d-button-label accepted-cleanup">Accepted solution</span>').insertAfter($solvedButton.find('i'))

    // mark row as answer
    $solvedButton.closest('.row').addClass('accepted-solution')
  }
}

const initializeSolvedQuote = () => {
  // look for a "solved" quote
  const $solvedQuote = $('.quote .fa-check-square.fa.accepted').closest('.quote')
  console.log($solvedQuote)

  if ($solvedQuote.length > 0) {
    $('.see-post-cleanup').remove()
    const $insertLinkButtonAfter = $solvedQuote.find('blockquote')

    $solvedQuote.addClass('quote--accepted')
    $solvedQuote.find('a.back')
      .clone('http')
      .insertAfter($insertLinkButtonAfter)
      .wrap('<div class="blockquote reference see-post-cleanup">')
      .addClass('btn-primary create btn')
      .text("See post")
  }

  initializeSolvedButton()
}

window.onDiscoursePageChange = () => {
  initializeSolvedQuote()
  setTimeout(initializeSolvedQuote, 200)
  setTimeout(initializeSolvedQuote, 1000)
}

window.onDiscoursePostStreamRefresh = () => {
  initializeSolvedButton()
  setTimeout(initializeSolvedButton, 200)
  setTimeout(initializeSolvedButton, 1000)
}