if (window.jQuery == null) {
  window.$ = require('jquery')
  window.jQuery = window.$
}

let oldHeight = -1

let onResize = function () {
  let footer = $('footer.footer, footer.campaignfooter')
  let footerHeight = footer.innerHeight()
  if (oldHeight === footerHeight) {
    return
  } // skip if the height has not changed since last time

  $('.main.sticky-footer-page-content-wrapper').css({ marginBottom: +(footerHeight * -1) + 'px' })
  $('#sticky-footer-styling').remove()
  $('<style id=\'sticky-footer-styling\'>.main.sticky-footer-page-content-wrapper::after{height:' + footerHeight + 'px !important} </style>').appendTo('head')
}

module.exports = {
  init: () => {
    $(() => {
      onResize()
      $(window).resize(onResize)
    })
  },

  onResize: onResize
}
