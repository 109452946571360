$('.login-link').click(function () {
  $('.login').toggleClass('login--active')
  $('.d-header-icons').toggle()
  return false
})

var ua = navigator.userAgent, event = (ua.match(/iPad/i)) ? 'touchstart' : 'click'
$('html, body').on(event, function (e) {
  if ($(e.target).closest('.login-box').length == 0) {
    $('.login').removeClass('login--active')
    $('.d-header-icons').fadeIn()
  }
})
